<template>
    <div class="grid admintext-demo">
        <Toolbar class="toolbar_top">
            <template v-slot:start>
                <div class="my-2">
                    <h3 v-if="is_rte">{{ t('indicatorsRte') }}</h3>
                    <h3 v-else>{{ t('indicators') }}</h3>
                </div>
            </template>
        </Toolbar>
        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loadingchart" style="

          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          z-index: 2;">
            <i class="p-datatable-loading-icon pi-spin pi pi-spinner load_chart"></i>
        </div>


        <div class="col-12 ">
            <div class="card">
                <div class="grid formgrid">

                    <div class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartProgress()"/>
                        <Chart type="line" :data="chartDataProgress" :options="chartOptions" class="h-30rem"
                               ref="chartProgress" :plugins="[chartJsPlugin]"/>
                    </div>
                    <div class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartConfrmity()"/>
                        <Chart type="line" :data="chartDataConformity" :options="chartOptions" class="h-30rem"
                               ref="chartConformity" :plugins="[chartJsPlugin]"/>
                    </div>
                </div>

                <div class="grid formgrid" v-if="is_rte">
                    <div class="field-control col-12 md:col-6">

                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartECR()"/>
                        <Chart type="line" :data="chartDataECR" :options="chartOptions" class="h-30rem" ref="chartECR"
                               :plugins="[chartJsPlugin]"/>
                    </div>
                    <div class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartNbNoConformity()"/>
                        <Chart type="line" :data="chartDataNbNoConformity" :options="chartOptionsUnlimit" class="h-30rem"
                               ref="chartNbNoConformity" :plugins="[chartJsPlugin]"/>
                    </div>
                </div>
                <div class="grid formgrid" v-if="is_rte">
                    <div class="field-control col-12">
                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportOnComplianceStatus') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectTexte">
                                    <i class="layout-menuitem-icon pi pi-fw pi-list"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfTexts') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfRequirementReassess') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem">
                                    <i class="layout-menuitem-icon pi pi-fw pi-external-link"></i>
                                    <span class="layout-menuitem-text">{{ t('statisticsEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>

                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportsActionPlan') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanTask">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanTasks') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanCompliance">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanCompliance') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatistic">
                                    <i class="layout-menuitem-icon pi pi-fw pi-arrow-right"></i>
                                    <span class="layout-menuitem-text">{{
                                        t('listNonConformitiesWithoutCompletedAP')
                                        }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatistic">
                                    <i class="layout-menuitem-icon pi pi-fw pi-info-circle"></i>
                                    <span class="layout-menuitem-text">{{ t('expiredAP') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>
                    </div>

                </div>
                <div class="grid formgrid" v-if="!is_rte">
                    <div class="field-control col-12 md:col-6">
                        <Button icon="pi pi-print" class="button-table mr-2" mode="basic"
                                @click="exportChartNoConformityAP()"/>
                        <Chart type="line" :data="chartDataNoConformityAP" :options="chartOptionsUnlimit"
                               class="h-30rem" ref="chartNoConformityAP" :plugins="[chartJsPlugin]"/>
                    </div>
                    <div class="field-control col-12 md:col-6">
                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportOnComplianceStatus') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectTexte">
                                    <i class="layout-menuitem-icon pi pi-fw pi-list"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfTexts') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectEvaluation">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem">
                                    <i class="layout-menuitem-icon pi pi-fw pi-check-square"></i>
                                    <span class="layout-menuitem-text">{{ t('listOfRequirementReassess') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem">
                                    <i class="layout-menuitem-icon pi pi-fw pi-external-link"></i>
                                    <span class="layout-menuitem-text">{{ t('statisticsEvaluations') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>

                        <Toolbar class="toolbar_top">
                            <template v-slot:start>
                                <div class="my-2">
                                    <h3>{{ t('reportsActionPlan') }}</h3>
                                </div>
                            </template>
                        </Toolbar>
                        <ul role="menu" class="layout-menu clearfix">
                            <li class="layout-root-menuitem" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanTask">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanTasks') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem " role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectActionPlanCompliance">
                                    <i class="layout-menuitem-icon pi pi-fw pi-directions"></i>
                                    <span class="layout-menuitem-text">{{ t('listActionPlanCompliance') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatistic">
                                    <i class="layout-menuitem-icon pi pi-fw pi-arrow-right"></i>
                                    <span class="layout-menuitem-text">{{
                                        t('listNonConformitiesWithoutCompletedAP')
                                        }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                            <li class="layout-root-menuitem disabled" role="none">
                                <a href="javascript:void(0)" class="p-ripple" exact="" role="menuitem"
                                   @click="redirectStatistic">
                                    <i class="layout-menuitem-icon pi pi-fw pi-info-circle"></i>
                                    <span class="layout-menuitem-text">{{ t('expiredAP') }}</span>
                                    <span class="p-ink"></span></a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import {usePrimeVue} from "primevue/config";
import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {computed} from "vue";
import {saveAs} from 'file-saver';

export default {
    name: "Rapport",
    data() {
        let documentStyle = getComputedStyle(document.documentElement);
        return {
            chartJsPlugin: {
                beforeDraw: (chartCtx) => {
                    const ctx = chartCtx.canvas.getContext('2d');
                    ctx.save();
                    ctx.globalCompositeOperation = 'destination-over';
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, chartCtx.width, chartCtx.height);
                    ctx.restore();
                },
            },
            chartDataProgress: {
                labels: [],
                datasets: [{
                    label: 'Evolution mensuelle du taux d\'avancement',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartDataConformity: {
                labels: [],
                datasets: [{
                    label: 'Evolution mensuelle du taux de conformité',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },

            chartDataNoConformityAP: {
                labels: [],
                datasets: [{
                    label: 'Evolution des non-conformités sans plan d\'actions',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },

            chartDataECR: {
                labels: [],
                datasets: [{
                    label: 'INDICATEUR ECR',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartDataNbNoConformity: {
                labels: [],
                datasets: [{
                    label: 'INDICATEUR NOMBRE DE NON-CONFORMITES',
                    fill: false,
                    tension: 0,
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: [],
                }],
            },
            chartOptions: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            chartOptionsUnlimit: {
                scales: {
                    yAxes: [{
                        ticks: {}
                    }]
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top'
                    }
                },
            },
            listMonth: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            listSites: [],
            loadingchart: true,
        }
    },
    setup() {
        const primevue = usePrimeVue();
        const store = useStore();
        const id_site = computed(() => {
            return store.getters.currentSite.id
        });
        const type_site = computed(() => {
            return store.getters.currentSite.type
        });
        const is_rte = computed(() => {
            return store.getters.isRte
        })
        const list_site = computed(() => {
            return store.getters.getListSites
        })
        return {t: primevue.config.StoreTranslation.t, store, id_site, is_rte, type_site, list_site}
    },
    mounted() {
        this.loadingchart = true
        this.getEvolutionMonthly()
        this.getChartOptions()
        this.listSites = this.list_site
        this.getStatECR()

    },

    methods: {
        redirectTexte() {
            this.$router.push('/rapport-text');
        },
        redirectEvaluation() {
            this.$router.push('/rapport-evaluation');
        },
        redirectComplianceReevaluer() {
            this.$router.push('/rapport-compliance-reevaluer');
        },
        redirectStatistic() {
            this.$router.push('/');
        },
        redirectActionPlanTask() {
            this.$router.push('/rapport-action_plan_tasks');
        },

        redirectActionPlanCompliance() {
            this.$router.push('/rapport-action_plan_compliances');
        },
        getEvolutionMonthly() {
            // this.store.dispatch(Actions.REPORT_MONTHLY_EVOLUTION, {}).then((data) => {
            // this.store.dispatch(Actions.GET_SITES_STAT, {entity: this.id_site, graph_report: 'yes'}).then((data) => {
            this.store.dispatch(Actions.GET_STAT_CONFORMITE_SITE, {site: this.id_site, type_site: this.type_site.toLowerCase()}).then((data) => {
                let dataStatProgress = this.chartDataProgress.datasets[0].data
                let dataStatConformity = this.chartDataConformity.datasets[0].data
                let dataStatNoConformityAP = this.chartDataNoConformityAP.datasets[0].data

                let dataStatNbNoConformity = this.chartDataNbNoConformity.datasets[0].data
                let now = new Date();
                let moisActuel = data[data.length - 1] ? data[0].month : now.getMonth() + 1
                let anneeActuelle = data[data.length - 1] ? data[0].year : now.getFullYear()
                var result = this.moisEtAnneeAvant(moisActuel, anneeActuelle, 12);
                let dataLabels = result
                data.forEach(element => {
                    let indexData = dataLabels.indexOf(element.month + '/' + element.year)
                    dataStatProgress[indexData] = element.progression ? parseFloat(element.progression).toFixed(2) : 0
                    dataStatConformity[indexData] = element.conformity ? parseFloat(element.conformity).toFixed(2) : 0
                    dataStatNoConformityAP[indexData] = element.non_conformity_no_pa ? parseFloat(element.non_conformity_no_pa).toFixed(0) : 0

                    dataStatNbNoConformity[indexData] = element.nbr_non_conformity ? parseFloat(element.nbr_non_conformity).toFixed(2) : 0
                })
                this.chartDataProgress.labels = dataLabels
                this.chartDataProgress.datasets[0].data = dataStatProgress

                this.chartDataConformity.datasets[0].data = dataStatConformity
                this.chartDataConformity.labels = dataLabels

                this.chartDataNoConformityAP.datasets[0].data = dataStatNoConformityAP
                this.chartDataNoConformityAP.labels = dataLabels

                this.chartDataNbNoConformity.datasets[0].data = dataStatNbNoConformity
                this.chartDataNbNoConformity.labels = dataLabels

                if (this.type_site.toUpperCase() != 'ENTITY')
                    this.loadingchart = false
            })
        },
        getChartOptions() {
            const documentStyle = getComputedStyle(document.documentElement);
            const textColor = documentStyle.getPropertyValue('--text-color');
            const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
            const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
            this.chartOptions = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },

                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,
                        max: 100,
                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }

            this.chartOptionsUnlimit = {
                maintainAspectRatio: false,
                aspectRatio: 0.6,
                plugins: {

                    datalabels: {
                        anchor: 'end',
                        align: 'top',
                        display: true,
                        font: {
                            weight: 600
                        }
                    },
                    legend: {
                        labels: {
                            color: textColor
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: textColorSecondary
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    },
                    y: {
                        min: 0,

                        ticks: {
                            color: textColorSecondary,
                            beginAtZero: true,
                        },
                        grid: {
                            color: surfaceBorder
                        }
                    }
                }
            }
        },

        moisEtAnneeAvant(moisActuel, anneeActuelle) {
            var result = [];
            var date = new Date(anneeActuelle, moisActuel, 1);
            date.setMonth(date.getMonth() - 2);
            for (let i = 0; i < 12; i++) {
                date.setMonth(date.getMonth() + 1);
                let mois = date.getMonth();
                let annee = date.getFullYear();
                let dataS = mois + 1 + '/' + annee;
                result.push(dataS);
            }
            return result;
        },
        exportChartProgress() {
            saveAs(this.$refs.chartProgress.getChart().toBase64Image('image/jpeg', 1), 'Evolution-mensuelle-taux-avancement.jpeg');
        },

        exportChartConfrmity() {
            saveAs(this.$refs.chartConformity.getChart().toBase64Image('image/jpeg', 1), 'Evolution-mensuelle-taux-conformité.jpeg');
        },
        exportChartECR() {
            saveAs(this.$refs.chartECR.getChart().toBase64Image('image/jpeg', 1), 'Indicateur-ECR.jpeg');
        },
        exportChartNbNoConformity() {
            saveAs(this.$refs.chartNbNoConformity.getChart().toBase64Image('image/jpeg', 1), 'Indicateur-nombre-non-conformité.jpeg');
        },
        exportChartNoConformityAP() {
            saveAs(this.$refs.chartNoConformityAP.getChart().toBase64Image('image/jpeg', 1), 'Evolution-non-conformiés-sans-PA.jpeg');
        },
        getStatECR() {
            let sites = this.listSites?.length > 0 ? this.listSites : [this.id_site]
            this.store.dispatch(Actions.GET_STAT_ECR, {sitesFils: sites}).then((data) => {
                let dataStatIndicatorECR = []

                let now = new Date();
                let moisActuel = data[data.length - 1] ? data[0].month : now.getMonth() + 1
                let anneeActuelle = data[data.length - 1] ? data[0].year : now.getFullYear()
                var result = this.moisEtAnneeAvant(moisActuel, anneeActuelle, 12);
                let dataLabels = result
                data.forEach(element => {
                    let indexData = dataLabels.indexOf(element.month + '/' + element.year)
                    dataStatIndicatorECR[indexData] = element.ECR_100 ? parseFloat(element.ECR_100).toFixed(2) : 0
                })
                this.chartDataECR.datasets[0].data = dataStatIndicatorECR
                this.chartDataECR.labels = dataLabels
                this.loadingchart = false
            })
        }

    },
    watch: {
        list_site(value) {
            if (value) {
                this.listSites = value
                this.getStatECR()
            }
        }
    }
}
</script>

<style scoped>

</style>